import React, { useState } from 'react'
import style from './LandingPage.module.css'
import ileft from '../assets/icon.svg'
import flag from '../assets/flag.svg'
import icon from '../assets/M.png'
import micro from '../assets/micro.svg'
import vector from '../assets/Vector.svg'
import x from '../assets/x.svg'
import love from '../assets/lov.svg'
import car from '../assets/c.svg'


import first from '../assets/Frame 1171274887.svg'
import design from '../assets/Frame 1000007407.svg'
import plus from '../assets/button-icon.svg'
import cate1 from '../assets/Frame 1000007411.svg'
import cate2 from '../assets/Frame 1000007412.svg'
import cate3 from '../assets/Frame 1000007414.svg'
import serve1 from '../assets/Frame 1000007431.svg'
import serve2 from '../assets/serve2.svg'
import serve3 from '../assets/box.svg'
import serve4 from '../assets/ship.svg'
import see from '../assets/button-icon.svg'
import free from '../assets/free.svg'



import shop from '../assets/Frame 1000007427.svg'
import pop1 from '../assets/pop1.svg'
import pop2 from '../assets/pop2.svg'
import pop3 from '../assets/pop3.svg'
import pop4 from '../assets/pop4.svg'
import star from '../assets/star.svg'
import addto from '../assets/add to.svg'
import divider from '../assets/Divider.svg'
import media from '../assets/media.svg'
import m2 from '../assets/m2.svg'




export const LandingPage = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenu = () => {
  setMenuOpen(!menuOpen);
    }

    return (
        <div className={style.body}>

        <div className={style.header1}>

            <div className={style.tele}>
                <div>
                    <img src={ileft} className={style.icon} alt='icon' />
                </div>
                <div className={style.number}>
                    <h4> +234 7065292789</h4>
                </div>
          </div>

            <div className={style.get}>
              <h4>Get 50% off on selected items</h4>
            </div>

            <div className={style.slash}>
              <p><b>|</b></p>
            </div>

            <div className={style.shop}>
              <a href='s'><p>Shop now</p></a>
            </div>

            <div className={style.left}>

              <p className={style.e}>EN</p>
              <select className={style.ena1}>EN</select>
              <img src={flag} className={style.flag} alt='flag' />
              <p className={style.n}>Nigeria </p>
              <select className={style.ena2}>Nigeria</select>

            </div>
        </div>

        <div className={style.navbar}>
          <div className={style.iconm}>
          <img src={icon} className={style.iconm} alt='icon' /> 

          <div className={style.vector} onClick={handleMenu}> 
          { menuOpen ?  <img src={x} className={style.x} alt='icon' /> : <img src={vector} /> }
          </div>
          </div>

          <div className={style.menu}>
            <ul>
              <li><b><a href='#'>Fruits</a></b></li>
              <li><b><a href='#'>Vegetables</a></b></li>
              <li><b><a href='#'>Grains</a></b></li>
            </ul>
          </div>

          <div className={style.contain}>
          <img src={micro} className={style.srch1} alt='search' />
          <input className={style.srch2} type='text' placeholder='Search here' />
          </div>

        </div>

        <div className={style.com}>
          <div className={style.love}>
          <img src={love} className={style.h} alt='love' />
          <img src={car} className={style.i} alt='cart' />
          </div>

          <div className={style.cn}>
          <button class={style.cn1}>Login</button>
          <button class={style.cn2}>Sign up</button>          
          </div>

        </div>

        <div className={` ${menuOpen ? style.visible : style.ham } `}>

        <div className={style.mob2}>
          <img src={micro} className={style.srch1} alt='search' />
          <input className={style.srch2} type='text' placeholder='Search here' />
          </div>

        <div className={style.mob1}>
            <ul>
              <b><a href='#'>Fruits</a></b>
              <b><a href='#'>Vegetables</a></b>
              <b><a href='#'>Grains</a></b>
            </ul>
          </div>
        

        <div className={style.mob3}>
          <div className={style.mob4}>
          <img src={love} className={style.h} alt='love' />
          <img src={car} className={style.i} alt='cart' />
          </div>

          <div className={style.mob5}>
          <button class={style.cn1}>Login</button>
          <button class={style.cn2}>Sign up</button>            
          </div>

          </div>
          </div>




      <div>
            <img src={first} className={style.frameone} alt='frame' />
        </div>

        <div className={style.brand}>
          <h2>Brands</h2>
        </div>

        <div>
            <img src={design} className={style.design} alt='design' />
        </div>

        <div className={style.ca}>

          <h2>Categories</h2>

          <div className={style.view}> 
          <h3>View all</h3>
          <img src={plus} className={style.add} alt='add' />
          </div>
        
        </div>

        <div className={style.cate}>

            <div>
                <img src={cate1} className={style.cate1} alt='cate1'/>
              </div>

            <div>

              <div>
                  <img src={cate2} className={style.cate2} alt='cate2'/>
                </div>

              <div>
                  <img src={cate3} className={style.cate3} alt='cate3'/>
                </div>

            </div>
        
        </div>

        <div className={style.we}>
          <h2>We provide the <br></br> best customer experiences</h2>
        </div>
        

        <div className={style.serve}>

          <div className={style.servea}>

            <img src={serve1} className={style.serve1} alt='serve1' />
            <h3>Fresh Products</h3>
            <p>We ensure money-back guarantee if <br></br>
              the product is counterfeit
            </p>

          </div>

          <div className={style.servea}>
            
          <img src={serve2} className={style.serve1} alt='serve2' />
            <h3>Satisfaction Guarantee</h3>
            <p>We ensure money-back guarantee if <br></br>
              the product is counterfeit
            </p>

          </div>

          <div className={style.servea}>

            <img src={serve3} className={style.serve1} alt='serve3' />
            <h3>New Arrival Everyday</h3>
            <p>We ensure money-back guarantee if <br></br>
              the product is counterfeit
            </p>

          </div>

          <div className={style.servea}>
            
          <img src={serve4} className={style.serve1} alt='serve4' />
            <h3>Fast & Free Shipping</h3>
            <p>We ensure money-back guarantee if <br></br>
              the product is counterfeit
            </p>

          </div>

        </div>

        <div className={style.sent}>

          <div className={style.sentone}>

          <div className={style.sent1}>

            <img src={serve1} alt='serve1' />
            <h3>Fresh Products</h3>
            <p>We ensure money-back <br></br> guarantee if
              the product <br></br> is counterfeit
            </p>

          </div>

          <div className={style.sent1}>
            
          <img src={serve2} alt='serve2' />
            <h3>Satisfaction Guarantee</h3>
            <p>We ensure money-back <br></br> guarantee if 
              the product <br></br> is counterfeit
            </p>

          </div>

          </div>

          <div className={style.sent1}>

          <div className={style.sent3}>

            <img src={serve3} alt='serve3' />
            <h3>New Arrival Everyday</h3>
            <p>We ensure money-back <br></br> guarantee if
              the product <br></br> is counterfeit
            </p>

          </div>

          <div className={style.sent1}>
            
          <img src={serve4} alt='serve4' />
            <h3>Fast & Free Shipping</h3>
            <p>We ensure money-back <br></br> guarantee if
              the product <br></br> is  counterfeit
            </p>

          </div>
          </div>

        </div>

        
        <div>
            <img src={shop} className={style.shop2} alt='shop' />
          </div>


          <div className={style.brand2}>
            <h2>Popular</h2>

            <div>
              <img src={see} className={style.see} alt='shop' />
            </div>

          </div>


          <div className={style.pop}>

            <div className={style.pop1}>
                <img src={pop1} className={style.popi} alt='shop' />

                <div className={style.price}>
                  <p>Court Heals </p>
                  <p className={style.pp}><span>$</span><b>95</b><span>.00</span> </p>
                  </div>
      
                <p>Red silettos</p>

                <div>
                    <img src={star} className={style.star} alt='shop' />
                </div>

                <div>
                    <img  src={addto} className={style.addto} alt='shop' />
                </div>
                
              </div>

              <div className={style.pop1}>
                <img src={pop2} className={style.popi} alt='shop' />

                <div className={style.price}>
                  <p>Court Heals </p>
                  <p className={style.pp}><span>$</span><b>95</b><span>.00</span> </p>
                  </div>
      
                <p>Red silettos</p>

                <div>
                    <img src={star} className={style.star} alt='shop' />
                </div>

                <div>
                    <img  src={addto} className={style.addto} alt='shop' />
                </div>

              </div>

              <div className={style.pop1}>
                <img src={pop3} className={style.popi} alt='shop' />

                <div className={style.price}>
                  <p>Court Heals </p>
                  <p className={style.pp}><span>$</span><b>95</b><span>.00</span> </p>
                  </div>
      
                <p>Red silettos</p>

                <div>
                    <img src={star} className={style.star} alt='shop' />
                </div>

                <div>
                    <img  src={addto} className={style.addto} alt='shop' />
                </div>

              </div>

              <div className={style.pop1}>
                <img src={pop4} className={style.popi} alt='shop' />

                <div className={style.price}>
                  <p>Court Heals </p>
                  <p className={style.pp}><span>$</span><b>95</b><span>.00</span> </p>
                  </div>
      
                <p>Red silettos</p>

                <div>
                    <img src={star} className={style.star} alt='shop' />
                </div>

                <div>
                    <img  src={addto} className={style.addto} alt='shop' />
                </div>

              </div>

          </div>


          <div>
            <img src={free} className={style.shop3} alt='free' />
          </div>


        <div className={style.footer}>
          <div>
            <p>LIKE WHAT YOU SEE?</p>
          </div>

          <div>
            <h1>Let's work together</h1>
          </div>

          <div>
            <p>If you're looking for a kick-ass product design <br></br>
            agency, we're here to help!
            </p>
          </div>

        </div>
        
        <div>
            <img src={divider} className={style.divider} alt='divider' />
        </div>

        <div className={style.footer2}>
            <img src={media} className={style.media} alt='media' />
            <p> &copy; 2024 Dark Arashi. All right resevered.</p>
            <img src={m2} className={style.m2} alt='M' />

        </div>

    </div>
    )
}

export default LandingPage;