// import logo from './logo.svg';
import React from 'react'
import LandingPage, {Landing} from  './LandingPage/LandingPage';
// import {Must} from './Must/Must';

function App() {

  return (
    // <div> <Must /></div>

    <div><LandingPage /></div>
  );
}

export default App;

